import React, { useState, useEffect, useCallback } from "react";
import { Disclosure } from '@headlessui/react'
import logo from "../media/logo.png";
import { Api } from "../components/Knihovna"
import { useNavigate } from 'react-router-dom';

function Quiz() {
    const [type, setType] = useState(0)
    const [diff, setDiff] = useState(0);
    // const [currentQuestion,setCurrentQuestion] = use State(0); 
    let [i, setI] = useState(0);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [corr, setCorr] = useState(0);
    let [bgcorrect, setBgcorrect] = useState(["","","",""])

    const otazkyNadpis = data.length > 0 ? data[i].text : '';

    const odpoved1 = data.length > 0 ? data[i].options[0] : '';
    const odpoved2 = data.length > 0 ? data[i].options[1] : '';
    const odpoved3 = data.length > 0 ? data[i].options[2] : '';
    const odpoved4 = data.length > 0 ? data[i].options[3] : '';


    function ChangeQuestion() {
        if (i < data.length - 1) {
            setI(i + 1)
            localStorage.setItem("currentQuestion", JSON.stringify(i + 1))
        }
        else {
            if (diff !== 4) {
                Api.setDifficulty(localStorage.getItem("userId"), type, diff + 1)

                let res = { easy: 0, medium: 0, hard: 0 }
                if (diff === 1) {
                    res.easy = corr
                }
                else if (diff === 2) {
                    res.medium = corr;
                }
                else if (diff === 3) {
                    res.hard = corr;
                }

                Api.addScore(localStorage.getItem("userId"), res, 4)
            }
            localStorage.setItem("currentQuestion", null)
            localStorage.setItem("currentQuestionType", null)
            localStorage.setItem("inQuiz", false)
            navigate("/dash")
            setVisible(false);
        }
    }
    function CheckAnswer(num) 
    {

        if (data[i].options[num].optionIsTrue === true)
        {
            setCorr(corr + 1); 
        } 

        let temp = [];
        for(let j = 0; j < data[i].options.length; j++)
        {
            if(data[i].options[j].optionIsTrue === true)
            {
                temp.push("bg-correct")
            }
            else
            {
                temp.push("bg-incorrect")
            }
        }
        setBgcorrect(temp)
        setTimeout(() => {
            setBgcorrect(["","","",""])
            ChangeQuestion()
          }, 1500)
   
    }

    const [visible, setVisible] = useState(false);
    const [ready] = useState(false);

    //Vyvojař = 1
    //Grafik = 2
    //Filmař = 30

    const getQuestions = useCallback(async () => {

        if (localStorage.getItem("obor: " + type) === null) {
            await Api.getQuestions(type).then((res) => {
                localStorage.setItem("obor: " + type, JSON.stringify(res));
            })
        }

        if (data.length === 0) {
            let res = await Api.getDifficulty(localStorage.getItem("userId"), type)
            setDiff(res.currentDiff)
            if (res.currentDiff === 4) {
                localStorage.setItem("currentQuestion", null)
                localStorage.setItem("currentQuestionType", null)
                navigate("/dash")
            }
            setData(JSON.parse(localStorage.getItem("obor: " + type))[res.currentDiff - 1])
            setVisible(true)
        }
    }, [type, data, setDiff, navigate])

    useEffect(() => {
        const userId = localStorage.getItem("userId");
        const refreshToken = localStorage.getItem("refreshToken")
        const inQuiz = JSON.parse(localStorage.getItem("inQuiz"));
        setType(localStorage.getItem("currentQuestionType"));

        if (userId == null || refreshToken == null || inQuiz !== true) {
            navigate('/dash');
        }
        else {

            if (JSON.parse(localStorage.getItem("currentQuestion")) === null) {
                localStorage.setItem("currentQuestion", 0)
            }
            else {
                setI(JSON.parse(localStorage.getItem("currentQuestion")))
            }

            if (type !== 0 && type < 4) {
                getQuestions()
            }


        }
    }, [type, navigate, getQuestions]);
    return (
        <>
            <Disclosure
                as="nav"
                className="bg-gray-800 bg-peknaCerna text-bila"
            >
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="flex h-16 items-center justify-between">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <button
                                            className="cursor-default mt-4"
                                        >
                                            <img
                                                className="h-10 w-13"
                                                src={logo}
                                                alt="cehace"
                                            />
                                        </button>
                                    </div>
                                    <h1 className="text-white font-bold text-3xl px-5">
                                        Quiz
                                    </h1>
                                </div>

                            </div>
                        </div>


                    </>
                )}
            </Disclosure>
            <div>
                {ready ? null : (
                    //abc
                    <div className="font-bold">
                        <h1 className="text-center text-xl mb-4 text-cerna bg-zluta p-5">{otazkyNadpis}</h1>
                        <div className="flex justify-center">
                            <div className="w-full max-w-screen-sm">
                                <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 px-3">
                                    <button
                                        className={`p-5 border-solid border-black text-bila rounded ${bgcorrect[0] === "" ? "bg-cervena" : bgcorrect[0]}`}
                                        onClick={() => CheckAnswer(0)}
                                    >
                                        {odpoved1.optionText}
                                    </button>
                                    <button
                                        className={`p-5 border-solid border-black text-bila rounded ${bgcorrect[1] === "" ? "bg-modra" : bgcorrect[1]}`}
                                        onClick={() => CheckAnswer(1)}
                                    >
                                        {odpoved2.optionText}
                                    </button>
                                    <button
                                        className={`p-5 border-solid border-black text-bila rounded ${bgcorrect[2] === "" ? "bg-oranzova" : bgcorrect[2]}`}
                                        onClick={() => CheckAnswer(2)}
                                    >
                                        {odpoved3.optionText}
                                    </button>
                                    <button
                                        className={`p-5 border-solid border-black text-bila rounded ${bgcorrect[3] === "" ? "bg-zelena" : bgcorrect[3]}`}
                                        onClick={() => CheckAnswer(3)}
                                    >
                                        {odpoved4.optionText}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {visible ? null : (
                    <div className="text-zluta">
                        <p> </p>
                    </div>
                )}

            </div>
        </>
    );
}

export default Quiz;
