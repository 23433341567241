import React, { useState, useEffect, useRef } from 'react'
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react'
import { Disclosure, Dialog, Menu, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import QRCode from "react-qr-code";
import logo from "../media/logo.png";
import map1 from "../media/map-1.png";
import map2 from "../media/map-2.png";
import map3 from "../media/map-3.png";
import img1 from "../media/IMG_3231.JPG";
import img2 from "../media/IMG_20231018_192528.jpg";
import img3 from "../media/IMG_3246.jpg";
import img4 from "../media/IMG_3248.jpg";
import img5 from "../media/IMG_4925.jpg";
import img6 from "../media/IMG_4971.JPG";
import img7 from "../media/spanek.png";
import { Api } from "../components/Knihovna";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/*
        kdyz dostane ban tak stejny proces akorat misto end a setEnd je ban a setBan  <------------------  HONZIK A TOBAN
*/


export default function Dash() {

    const [openC, setOpenC] = useState(true);
    const cancelButtonRefC = useRef(null);

    const [openB, setOpenB] = useState(true);
    const cancelButtonRefB = useRef(null);

    const [openTP, setOpenTP] = useState(true);
    const cancelButtonRefTP = useRef(null);

    const [page, setPage] = useState(1);
    const [miniPage, setMiniPage] = useState(1);
    const qr = localStorage.getItem("userId");
    const navigate = useNavigate();
    const [score, setScore] = useState(localStorage.getItem("score"));
    const [message, setMessage] = useState(null);
    const [end, setEnd] = useState(false);
    const [ban, setBan] = useState(false);                          //totototototototoot honzikuuuuuu
    const [technickePotize, setTechPot] = useState(false);
    const [claim, setClaim] = useState(false);

    useEffect(() => {

        const userId = localStorage.getItem("userId");
        const refreshToken = localStorage.getItem("refreshToken");

        if (userId == null || refreshToken == null) {
            navigate('/');
        }

        if (JSON.parse(localStorage.getItem("inQuiz")) === true) {
            navigate("/quiz")
        }
    });


    useEffect(() => {
        const intervalId = setInterval(() => {
            Api.getScore().then((res) => {
                if (res !== null) {
                    localStorage.setItem("score", res.score);
                    setScore(res.score);
                    if (res.score >= 1200 && localStorage.getItem("end") === "ne") {
                        setEnd(true);
                        localStorage.setItem("end", "ano");
                    }
                }
            })

        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);


    function restart() {
        localStorage.clear();
        navigate("/")
    }

    // Get score
    useEffect(() => {

        if (localStorage.getItem("scannedQr") == null) {
            let arr = [];
            localStorage.setItem("scannedQr", JSON.stringify(arr));
        }
    })

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    const akce = (result) => {

        if (result === "dev") {
            restart()
        }
        else if (result === "odznacekClaim")
        {
            if(localStorage.getItem("test") === null && score >= 1200){
                if(JSON.parse(localStorage.getItem("odznacek")) === null)
            {
                localStorage.setItem("test" , 1)
                setClaim(true);
                localStorage.setItem("odznacek",1);
                console.log("dikz");
                
                
            }
            else
            {
                alert("odznáček sis už vyzvedl/a")
            }
            }
            else if(localStorage.getItem("test") === null )
            {
                alert("nemáš na odznak nárok")

            }
            
        }
        else if (isJson(result) && (JSON.parse(result).type === "codeQuestion") && (localStorage.getItem("isCodeQuestion") !== 1)) {
            const data = JSON.parse(result);
            Api.connectCodeQuestion(localStorage.getItem("userId"), data.id)
            localStorage.setItem("isCodeQuestion", 1);
        }
        else if(!result.includes("http")) {
            let arr = JSON.parse(localStorage.getItem("scannedQr"));
            if (!(arr.includes(result))) {
                Api.getQrCodeType(localStorage.getItem("userId"), result).then((res) => {
                    if (res === null) {
                        console.log("invalid qr code")
                    }
                    else if (res.qrType === 1) {

                        arr.push(result)
                        localStorage.setItem("scannedQr", JSON.stringify(arr));
                        console.log(arr)
                        localStorage.setItem("currentQuestionType", res.type);
                        localStorage.setItem("inQuiz", true);
                        navigate(`/quiz`);



                    }
                    else if (res.qrType === 2) {
                        console.log(res.message)
                        setMessage(res.message);

                        /*arr.push(result)
                        localStorage.setItem("scannedQr", JSON.stringify(arr));*/


                    }
                    else if (res.qrType === 3) {
                        arr.push(result)
                        toast.success("Bodíky byli přičteny!")
                        localStorage.setItem("scannedQr", JSON.stringify(arr));
                    }


                })
            }
            else {
                try {
                    document.getElementById('qrCheck').classList.remove('hidden');
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }


    return (
        <>
            <ToastContainer />
            <Disclosure as="nav" className="bg-gray-800 bg-peknaCerna text-bila">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="flex h-16 items-center justify-between">
                                <div className="flex items-center">
                                    <div className="flex flex-shrink-0 flex-row gap-5">
                                        <button className='cursor-default pt-2' onClick={() => setPage(5)}><img
                                            className="h-10 w-13"
                                            src={logo}
                                            alt="cehace"
                                        /></button>
                                        <div className="text-lg font-medium leading-none text-gray-400 flex mt-4">
                                            <p className='mr-1 sm:ml-2'>Tvoje skóre:</p>
                                            <p className='text-zluta'>{score}</p>         {/*------------ tady bude skore-------------------------- */}
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-10 flex items-baseline">
                                            <Disclosure.Button className='text-bila hover:bg-zluta hover:text-white hover:transition-all px-5 py-6 text-sm font-medium' onClick={() => setPage(1)}>Scan</Disclosure.Button>
                                            <Disclosure.Button className='text-bila hover:bg-zluta hover:text-white hover:transition-all px-5 py-6 text-sm font-medium' onClick={() => setPage(2)}>Qr</Disclosure.Button>
                                            <Disclosure.Button className='text-bila hover:bg-zluta hover:text-white hover:transition-all px-5 py-6 text-sm font-medium' onClick={() => setPage(3)}>Map</Disclosure.Button>
                                            <Disclosure.Button className='text-bila hover:bg-zluta hover:text-white hover:transition-all px-5 py-6 text-sm font-medium' onClick={() => setPage(4)}>Tutorial</Disclosure.Button>
                                            <div className="col-12 flex flex-row flex-end">
                                                <h1 className="font-medium text-xl ml-7 mb-2 leading-none text-white">{localStorage.getItem("nick")}</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-4 flex items-center md:ml-6">
                                        {/* Profile dropdown */}
                                        <Menu as="div" className="relative ml-3">
                                            <div>
                                                <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                    <span className="absolute -inset-1.5" />
                                                    <span className="sr-only">Open user menu</span>
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute bg-peknaCerna right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <Disclosure.Button className='text-bila hover:bg-zluta hover:text-white px-3 py-2 text-sm font-medium' onClick={() => setPage(1)}>Scan</Disclosure.Button>
                                                    <Disclosure.Button className='text-bila hover:bg-zluta hover:text-white px-3 py-2 text-sm font-medium' onClick={() => setPage(2)}>Qr</Disclosure.Button>
                                                    <Disclosure.Button className='text-bila hover:bg-zluta hover:text-white px-3 py-2 text-sm font-medium' onClick={() => setPage(3)}>Map</Disclosure.Button>
                                                    <Disclosure.Button className='text-bila hover:bg-zluta hover:text-white px-3 py-2 text-sm font-medium' onClick={() => setPage(4)}>Tutorial</Disclosure.Button>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                                <div className="-mr-2 flex md:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                <Disclosure.Button className='bg-gray-900 text-white text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 text-base font-medium' onClick={() => setPage(1)}>Scan</Disclosure.Button>
                                <Disclosure.Button className='bg-gray-900 text-white text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 text-base font-medium' onClick={() => setPage(2)}>Qr</Disclosure.Button>
                                <Disclosure.Button className='bg-gray-900 text-white text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 text-base font-medium' onClick={() => setPage(3)}>Map</Disclosure.Button>
                                <Disclosure.Button className='bg-gray-900 text-white text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 text-base font-medium' onClick={() => setPage(4)}>Tutorial</Disclosure.Button>
                            </div>
                            <div className="border-t border-gray-700 pb-3 pt-2">
                                <div className="flex items-center px-5 my-3 space-y-1">
                                    <div className="ml-3">
                                        <h1 className="font-medium text-xl mb-2 leading-none text-white">{localStorage.getItem("nick")}</h1>
                                        <div className="text-sm font-medium leading-none text-gray-400 flex">
                                            <p className='mr-1'>Tvoje skóre:</p>
                                            <p className='text-zluta'>{score}</p>         {/*------------ tady bude skore-------------------------- */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            {message && <Transition.Root show={openC} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRefC}
                    onClose={setOpenC}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-peknaCerna bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex mt-32 items-end justify-center px-5 pt-10 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-bila text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-5 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-3xl mb-7 mt-3 font-semibold leading-6 text-gray-900"
                                                >
                                                    Tajná zpráva
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-lg text-gray-500" dangerouslySetInnerHTML={{ __html: message }}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 bg-zluta inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() =>
                                                setMessage(null)
                                            }
                                            ref={cancelButtonRefC}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>}

            {end && <Transition.Root show={openC} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRefC}
                    onClose={setOpenC}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-peknaCerna bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex mt-32 items-end justify-center px-5 pt-10 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-bila text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-5 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-3xl mb-7 mt-3 font-semibold leading-6 text-gray-900"
                                                >
                                                    Dobrá práce! Získal jsi odznáček!
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-lg text-gray-500 mb-3">Získal jsi dost bodů pro vyzvednutí odznáčku.</p>
                                                    <p className="text-lg text-gray-500">Teď jen stačí dojít <b>k vchodu</b> a ukázat počet bodů v aplikaci!</p>
                                                    <button onClick={() => setOpenB(true)}></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 bg-zluta inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => {
                                                setEnd(false);
                                            }
                                            }
                                            ref={cancelButtonRefC}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>}

            {claim && <Transition.Root show={openC} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRefC}
                    onClose={setOpenC}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-peknaCerna bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex mt-32 items-end justify-center px-5 pt-10 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-bila text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-5 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-3xl mb-7 mt-3 font-semibold leading-6 text-gray-900"
                                                >
                                                    Všechny podmínky splněny!
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-lg text-gray-500 mb-3">Vydání odznáčku může proběhnout v pořádku.</p>
                                                    
                                                    {/*<button onClick={() => setOpenB(true)}></button>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 bg-zluta inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => {
                                                setClaim(false);
                                                localStorage.removeItem("test")
                                            }
                                            }
                                            ref={cancelButtonRefC}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>}

            {ban && <Transition.Root show={openB} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRefC}
                    onClose={setOpenC}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-peknaCerna bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex mt-32 items-end justify-center px-5 pt-10 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-bila text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-5 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationTriangleIcon
                                                    className="h-6 w-6 text-red-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-3xl mb-7 mt-3 font-semibold leading-6 text-gray-900"
                                                >
                                                    Ale nééé... dostal jsi ban!
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-lg text-gray-500 mb-3">Buď jsi podváděl nebo máš ofenzivní jméno.</p>
                                                    <p className="text-lg text-gray-500">Pokud to byl omyl a nebo slibuješ že už to neuděláš, dojdi za kýmkoliv ze <b>staff týmu</b>. Najdeš ho podle toho že má žluté tričko <b>"Jdi do ředitelny"</b>.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 bg-zluta inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => {
                                                setBan(false);
                                            }
                                            }
                                            ref={cancelButtonRefB}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>}

            {technickePotize && <Transition.Root show={openTP} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    initialFocus={cancelButtonRefTP}
                    onClose={setOpenTP}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-peknaCerna bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex mt-32 items-end justify-center px-5 pt-10 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-bila text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-lg">
                                    <div className="bg-white px-5 pb-4 pt-5 sm:p-6 sm:pb-4">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationTriangleIcon
                                                    className="h-6 w-6 text-red-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-3xl mb-7 mt-3 font-semibold leading-6 text-gray-900"
                                                >
                                                    A jéje... máme problém
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-lg text-gray-500 mb-3">Vyskytly se nám tady technické potíže, omlouváme se za problémy.</p>
                                                    <p className="text-lg text-gray-500">Náš tech team momentálně pracuje na vyřešení problému, hned jak to bude, můžeš pokračovat ve hraní.</p>
                                                    <p className="text-lg text-gray-500">Pokud máš nějaký dotaz, nebo tě zajímá za jak dlouho projekt pojede, dojdi za kýmkoliv ze <b>staff týmu</b>. Najdeš ho podle toho že má žluté tričko <b>"Jdi do ředitelny"</b>.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                        <button
                                            type="button"
                                            className="mt-3 bg-zluta inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            onClick={() => {
                                                setTechPot(false);
                                            
                                            }
                                            }
                                            ref={cancelButtonRefTP}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>}

            <section>
                {page === 1 && <div>
                    <header className="bg-zluta shadow">
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Scan</h1>
                        </div>
                    </header>
                    <div className="w-100">
                        <div className=' w-96 h-96 mx-auto mt-7'>
                            <QrScanner
                                onDecode={(result) => akce(result)}
                                onError={(error) => console.log(error?.message)}
                            />
                            <p id='qrCheck' className='text-cervena hidden'>Tento QR kód byl již naskenován. Naskenujte prosím jiný.</p>
                            <p className='mt-3'>Naskenujte QR kód úkolu</p>
                        </div>
                    </div>

                </div>}

                {page === 2 && <div>
                    <header className="bg-zluta shadow">
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900">QR code user</h1>
                        </div>
                    </header>
                    <div className="flex mt-10 justify-center">
                        <QRCode value={qr} />
                    </div>

                </div>}

                {page === 3 && <div className='overflow-x-hidden mb-10'>
                    <header className="bg-zluta shadow">
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Mapa</h1>
                        </div>
                    </header>
                    <div className="w-100">
                        {/* grid pro tlacitka na prepinani obrazku */}
                        <div className="rounded-sm mt-10 flex gap-3 justify-center">
                            <button className="px-2 py-1 hover:text-bila hover:bg-peknaCerna hover:transition-all text-lg rounded bg-zluta focus:bg-cerna focus:text-bila" onClick={() => setMiniPage(1)} autoFocus>První patro</button>
                            <button className="px-2 py-1 hover:text-bila hover:bg-peknaCerna hover:transition-all text-lg rounded bg-zluta focus:bg-cerna focus:text-bila" onClick={() => setMiniPage(2)}>Druhé patro</button>
                            <button className="px-2 py-1 hover:text-bila hover:bg-peknaCerna hover:transition-all text-lg rounded bg-zluta focus:bg-cerna focus:text-bila" onClick={() => setMiniPage(3)}>Třetí patro</button>
                        </div>

                        {miniPage === 1 && <img className='w-9/12 md:w-6/12 lg:w-1/3 mx-auto mt-10' src={map1} alt="" />}
                        {miniPage === 2 && <img className='w-9/12 md:w-6/12 lg:w-1/3 mx-auto mt-10' src={map2} alt="" />}
                        {miniPage === 3 && <img className='w-9/12 md:w-6/12 lg:w-1/3 mx-auto mt-10' src={map3} alt="" />}

                    </div>
                </div>}

                {page === 4 && <div>
                    <header className="bg-zluta shadow">
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Tutorial</h1>
                        </div>
                    </header>
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                        <div className="grid grid-cols-1 gap-16 row-gap-8 lg:grid-cols-1">
                            <div className="space-y-8">
                                <div>
                                    <p className="mb-4 text-xl font-medium">
                                        O hře:
                                    </p>
                                    <p className="text-peknaCerna mb-5"><b>Cílem hry </b> je představit naší školu všem zájemcům, kteří se dostavili na den otevřených dveří. Hráč musí nasbírat za všechny aktivity co nejvíce bodů a každý zúčastněný, který nasbírá stanovený počet bodů, dostane CHC odznáček.</p>
                                    <p className="text-peknaCerna mb-5"><b>Stanoviště </b> jsou místa, na kterých naleznete QR kódy, které obsahují různé interaktivní hry nebo kvízy.</p>
                                    <p className="text-peknaCerna mb-5"><b>Bodování </b> je stanoveno tak, že za každou aktivitu dostane hráč určitý počet bodů.</p>
                                </div>
                                <div>
                                    <p className="mb-4 text-xl font-medium">
                                        Jak se připojíte do hry?
                                    </p>
                                    <p className="text-peknaCerna mb-5"><b>User profile: </b> Ve svém profilu uvidíte 3 karty.</p>
                                    <p className="text-peknaCerna mb-5"><b>1. Váš QR kód</b> - Ukážete dle potřeby na stanovištích.</p>
                                    <p className="text-peknaCerna mb-5"><b>2. Skenování QR kódů</b>- Zde naskenujete QR kódy, které budou na stanovištích.</p>
                                    <p className="text-peknaCerna mb-5"><b>3. Mapa školy </b>- Zde uvidíte mapu školy, na které jsou rozmístěné stanoviště.</p>
                                </div>
                                <div>
                                    <p className="mb-4 text-xl font-medium">
                                        Jaké úkoly zde naleznete?
                                    </p>
                                    <p className="text-peknaCerna mb-5">
                                        V kvízu se setkáte s otázkami z vybraného oboru, zahrajete si hry, které vytvořili naši studenti a otázky, které vás provedou školou. Kvízy se dělí na všechny 3 obory - vývojáři, grafici a filmaři, díky čemuž můžete zjistit více o jednotlivých oborech. Pokud budete potřebovat pomoct, stačí se obrátit na někoho na stanovišti.
                                    </p>
                                </div>
                                <div>
                                    <p className="mb-4 text-xl font-medium">
                                        Máte nejasnosti ohledně hry?
                                    </p>
                                    <p className="text-peknaCerna">
                                        V případě nejasností či potíží se obraťte na organizátory hry, kteří vám rádi pomohou. Ty poznáte podle <b>žlutých triček s nápisem "Běž do ředitelny"</b>.
                                    </p>
                                    <p className=' mb-5'>Také můžete zavolat na telefonní číslo <b>+420 735 509 895</b>.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>}
                {page === 5 && <div>
                    <header className="bg-zluta shadow">
                        <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Credits</h1>

                        </div>

                    </header>
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                        <p className='text-2xl font-bold text-center'>
                            Tým, který vytvořil aplikaci:
                        </p>
                        <ul className='text-center'>
                            <li><a href="https://www.instagram.com/matyasdrobik/" target='_blank' rel="noreferrer">Matyáš Drobík</a></li>
                            <li><a href="https://www.instagram.com/karelhaana/" target='_blank' rel="noreferrer">Karel Hána</a></li>
                            <li><a href="https://www.instagram.com/adamhornakk/" target='_blank' rel="noreferrer">Adam Horňák</a></li>
                            <li><a href="https://www.instagram.com/najky.svet/" target='_blank' rel="noreferrer">Niké Janochová</a></li>
                            <li><a href="https://www.instagram.com/janx.xyz/" target='_blank' rel="noreferrer">Metoděj Janota</a></li>
                            <li><a href="https://www.instagram.com/dzordys/" target='_blank' rel="noreferrer">Anežka Jordánová</a></li>
                            <li><a href="https://www.instagram.com/o._mlcak/" target='_blank' rel="noreferrer">Ondřej Mlčák</a></li>
                            <li><a href="https://www.instagram.com/lyrickysubjekt04/" target='_blank' rel="noreferrer">Bohumil Navrátil</a></li>
                            <li className='font-bold'><a href="https://www.instagram.com/tobi_ondra/" target='_blank' rel="noreferrer">Tobiáš Ondra</a></li>
                            <li><a href="https://www.instagram.com/michaelka.__/" target='_blank' rel="noreferrer">Michaela Ptáčková</a></li>
                            <li><a href="https://www.instagram.com/maxmilianruzicka/" target='_blank' rel="noreferrer">Maxmilián Růžička</a></li>
                            <li><a href="https://www.instagram.com/sedlacekk_/" target='_blank' rel="noreferrer">Vojtěch Sedláček</a></li>
                            <li className='font-bold'><a href="https://www.instagram.com/zednikhonza/" target='_blank' rel="noreferrer">Jan Zedník</a></li>
                            <li><a href="https://www.instagram.com/samuel_zarsky/" target='_blank' rel="noreferrer">Samuel Žárský</a></li>
                        </ul>
                        <h1 className='text-2xl font-bold mt-10 text-center'>Fotky z průběhu tvoření aplikace</h1>
                        <div>
                            <img src={img1} alt="" className='w-96 my-3 mx-auto' />
                            <figcaption className='text-center w-72 mx-auto mb-5'>V počítačovce se nejí - velká objednávka KFC, polívka od kuchařky a 4h strávené na záchodech</figcaption>
                            <img src={img2} alt="" className='w-96 my-3 mx-auto' />
                            <figcaption className='text-center w-72 mx-auto mb-5'>Příjemné pracovní podmínky na příštích 24 hodin</figcaption>
                            <img src={img3} alt="" className='w-96 my-3 mx-auto' />
                            <figcaption className='text-center w-72 mx-auto mb-5'>Nemohli jsme jezdit autem, tak jedeme židlí</figcaption>
                            <img src={img4} alt="" className='w-96 my-3 mx-auto' />
                            <figcaption className='text-center w-72 mx-auto mb-5'>Lidi si dělali už mnoho imaginárních kamarádů</figcaption>
                            <img src={img5} alt="" className='w-96 my-3 mx-auto' />
                            <figcaption className='text-center w-72 mx-auto mb-5'>V 5 ráno bylo naživu jen pár lidí ze 13 - dělání stylů byla zábava</figcaption>
                            <img src={img6} alt="" className='w-96 my-3 mx-auto' />
                            <figcaption className='text-center w-72 mx-auto mb-5'>Konečná "shitistika", kterou všichni účastnění spravedlivě vyplňovali</figcaption>
                            <img src={img7} alt="" className='w-96 my-3 mx-auto' />
                            <figcaption className='text-center w-72 mx-auto mb-5'>Takto jsme vypadali po 24 hodinách práce na tomto projektu</figcaption>
                        </div>
                    </div>
                </div>}

            </section>

        </>
    )
}