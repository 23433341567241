class Api {
    //static url = "http://localhost:3002"
    static url = "https://api.johnyserver.fun"
    //static urlAuth = "http://localhost:3003"
    static urlAuth = "https://apiauth.johnyserver.fun"

    // Create user
    static async createUser(username, password) {
        try {
            let res = await this.apiPost(this.urlAuth + "/register", { username: username, isAdmin: password, })
            return res
        }
        catch (err) {
            if (err === 409) {
                return 409
            }
            else {
                console.log(err)
                return null;
            }
        }
    }

    // Add score to user by ID
    static async addScore(id, result, idOfPlace) {
        try {
            await this.apiPost(this.url + "/score", { id: id, result: result, idOfPlace: idOfPlace })
        }
        catch (err) {
            console.log(err)
        }
    }

    static async getLeadboard() {
        try {
            let res = await this.apiGet(this.url + "/getLeadboard")
            return res
        }
        catch (err) {
            console.log(err)
            return null;
        }
    }

    static async connectCodeQuestion(userId, pcId) {
        try {
            await this.apiPost(this.url + "/connectCodeQuestion", { id: userId, refreshToken: await localStorage.getItem("refreshToken"), pcId: pcId })
        }
        catch (err) {
            console.log(err)
        }
    }

    static async updateUserType(userId, type) {
        try {
            let res = await this.apiPost(this.url + "/updateType", { id: userId, type: type })
            return res
        }
        catch (err) {
            console.log(err)
            return null;
        }
    }

    static async getUsers() {
        try {
            let res = await this.apiGet(this.url + "/getUsers")
            return res;
        }
        catch (err) {
            console.log(err)
            return null;
        }
    }

    // Get score
    static async getScore() {
        try {
            let res = await this.apiPost(this.url + "/getScore", { id: localStorage.getItem("userId") })
            return res;
        }
        catch (err) {
            console.log(err)
            return null;
        }
    }

    static async getQuestions(type) {
        try {
            let res = await this.apiPost(this.url + "/getQuestions", { userType: type })
            return res;
        }
        catch (err) {
            console.log(err)
            return null;
        }
    }

    static async getDifficulty(id, type) {
        try {
            let res = await this.apiPost(this.url + "/getDiff", { id: id, userType: type })
            return res;
        }
        catch (err) {
            console.log(err)
            return null;
        }
    }

    static async setDifficulty(id, type, diff) {
        try {
            await this.apiPost(this.url + "/setDiff", { id: id, userType: type, diff: diff })
        }
        catch (err) {
            console.log(err)
            return null;
        }
    }

    static async getQrCodeType(userId, qrId) {
        try {
            let res = await this.apiPost(this.url + "/getQrCodeType", { qrId: qrId, id: userId })
            return res;
        }
        catch (err) {
            console.log(err)
            return null;
        }
    }

    static async getCodeQuestions() {
        try {
            let res = await this.apiGet(this.url + "/getCodeQuestions")
            return res;
        }
        catch (err) {
            console.log(err)
            return null;
        }
    }

    static async apiGet(endpoint) {
        let token = false;
        do {
            try {
                const response = await fetch(endpoint,
                    {
                        method: "GET",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + await localStorage.getItem("token"),
                        },
                    })

                if (response.status === 410) {
                    await this.getToken();
                }
                else {
                    token = true;
                }
                if (response.status !== 200 && response.status !== 410) {
                    throw response.status;
                }
                else if (response.status === 200) {
                    const json = await response.json();
                    const res = json;
                    return res;
                }
            }
            catch (err) {
                console.log(err);
                throw err;
            }
        }
        while (token === false)
    }

    static async apiPost(endpoint, body) {
        let token = false;
        do {
            try {
                const response = await fetch(endpoint,
                    {
                        method: "POST",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + await localStorage.getItem("token"),
                        },
                        body: JSON.stringify(body),
                    })

                if (response.status === 410) {
                    await this.getToken();
                }
                else {
                    token = true;
                }
                if (response.status !== 200 && response.status !== 410) {
                    throw response.status;
                }
                else if (response.status === 200) {
                    const json = await response.json();
                    const res = json;
                    return res;
                }
            }
            catch (err) {
                console.log(err);
                throw err;
            }
        }
        while (!token)
    }

    static async getToken() {
        try {
            const response = await fetch(this.urlAuth + "/token", {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: localStorage.getItem("refreshToken") }),
            });

            if (response.status !== 200) {
                console.log("token: " + response.status);
                throw response.status;
            }
            else {
                const res = await response.json();
                await localStorage.setItem("token", res.accessToken);
                return response.status;
            }
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export { Api };
