import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Dash from "./pages/Dash";
import Quiz from "./pages/Quiz";


export default function App() {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path="/dash" element={<Dash />} />
                <Route path="/quiz" element={<Quiz />} />
            </Routes>
        </div>
    )
}